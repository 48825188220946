import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Classes from './Components/Classes';
import Subjects from './Components/Subjects';
import Announcements from './Components/Announcements';
import CreateAssignment from './Components/CreateAssignment';
import ViewAssignment from './Components/ViewAssignment';
import DueDetails from './Components/DueDetails';
import Exam from './Components/Exam';
import ViewAttendance from './Components/ViewAttendance';
import AttendanceForm from './Components/AttendanceForm';
import SubjectDetail from './Components/SubjectDetail';
import Lessons from './Components/Lessons';
import Topics from './Components/Topics';
import DashContent from './Components/DashContent';
import LessonAdd from './Components/LessonAdd';
import LessonEdit from './Components/LessonEdit';
import TopicAdd from './Components/TopicAdd';
import TopicEdit from './Components/TopicEdit';
import DashboardMain from './Components/DashboardMain';
import PendingAssignments from './Components/PendingAssignments';
import SubmittedAssignments from './Components/SubmittedAssignments';
import ViewAnnouncements from './Components/ViewAnnouncements';
import ProfileDetails from './Components/ProfileDetails';
import AccountForm from './Components/AccountForm';
import LogIn from './Components/LogIn';
import Test from './Components/Test';
import TimetablePage from './Components/TimetablePage';

function App() {
  const [teacherDetails, setTeacherDetails] = useState({
    name: "Mr. Smith",
    email: "mr.smith@example.com",
    subjects: ["Math", "Science"]
  });



  const [announcements, setAnnouncements] = useState([
    { id: 1, title: 'Holiday Announcement', content: 'School will be closed on 15th August.' },
    { id: 2, title: 'New Timetable Released', content: 'The new timetable is available on the school website.' },
  ]);


  const handleAddAnnouncement = (announcement) => {
    setAnnouncements([...announcements, announcement]);
  };

  return (

 
    <Router>
     <Routes>
     <Route path="/" element={<AccountForm />} />
            <Route path="/login" element={<LogIn />} />
             <Route path="/teachersDashboard" element={<DashboardMain content={<DashContent/>}/>}/>
            <Route path="/subjects" element={<DashboardMain content={<Subjects />} />}/>
            <Route path="/classes" element={<DashboardMain content={<Classes />} />}/>
            <Route path="/announcements" element={<DashboardMain content={<Announcements addAnnouncement={handleAddAnnouncement} />} />}/>
            <Route path="/assignmentcreate" element={<DashboardMain content={<CreateAssignment />} />}/>
            <Route path="/assignmentview" element={<DashboardMain content={<ViewAssignment />} />}/>
            <Route path="/attendance" element={<DashboardMain content={<ViewAttendance />} />}/>
            <Route path="/attendance-form" element={<DashboardMain content={<AttendanceForm />} />}/>
            <Route path="/due-details" element={<DashboardMain content={<DueDetails />} />}/>
            <Route path="/exam" element={<DashboardMain content={<Exam />} />}/>
            <Route path="/subject-detail" element={<DashboardMain content={<SubjectDetail />} />}/>
            <Route path="/lesson" element={<DashboardMain content={<Lessons />} />}/>
            <Route path="/addlesson" element={<DashboardMain content={< LessonAdd />} />}/>
            <Route path="/editlesson/:lessonId" element={<DashboardMain content={< LessonEdit />} />}/>
            <Route path="/topic" element={<DashboardMain content={<Topics />} />}/>
            <Route path="/addtopic" element={<DashboardMain content={<TopicAdd />} />}/>
            <Route path="/edittopic/:topicId" element={<DashboardMain content={<TopicEdit />} />}/>
            <Route path="/submitted-assignments" element={<DashboardMain content={<SubmittedAssignments/>}/>}/>
            <Route path="/pending-assignments" element={<DashboardMain content={<PendingAssignments/>}/>}/>
            <Route path="/view-announcements" element={<DashboardMain content={<ViewAnnouncements/>}/>}/>
            <Route path="/profile-details" element={<DashboardMain content={<ProfileDetails/>}/>}/>
            <Route path="/timetable" element={<DashboardMain content={<TimetablePage/>}/>}/>

          </Routes>
        
    </Router>
  );
}

export default App;