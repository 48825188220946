import React, { useState } from 'react';
import Classes from './Classes';
import Subjects from './Subjects';
import Navbar from './Navbar';
import Sidebar from './TeacherSidebar';
import NoticeCard from './NoticeCard';
import Announcements from './Announcements';
import DueCard from './DueCard';
import AttendanceChart from './AttendanceChart';
import TymTblCard from "./TymTblCard";
import AssignmentCard from './AssignmentCard';
import CreateAssignment from './CreateAssignment';
import ViewAssignment from './ViewAssignment';
import DueDetails from './DueDetails';
import Exam from './Exam';
import ViewAttendance from './ViewAttendance';
import AttendanceForm from './AttendanceForm';
import SubjectDetail from './SubjectDetail';
import Lessons from './Lessons';
import Topics from './Topics';
import hideArrowIcon from './hidearrow.png';

const DashContent = () => {
    const [teacherDetails, setTeacherDetails] = useState({
        name: "Mr. Smith",
        email: "mr.smith@example.com",
        subjects: ["Math", "Science"]
      });
    
      const [upcomingEvents, setUpcomingEvents] = useState([
        { id: 1, title: 'Semester Exams', datetime: '2024-08-20T09:00:00' },
        { id: 2, title: 'Parent-Teacher Meeting', datetime: '2024-08-25T18:00:00' },
        { id: 3, title: 'Science Fair', datetime: '2024-09-05T10:00:00' },
      ]);
    
      const [announcements, setAnnouncements] = useState([
        { id: 1, title: 'Holiday Announcement', content: 'School will be closed on 15th August.' },
        { id: 2, title: 'New Timetable Released', content: 'The new timetable is available on the school website.' },
      ]);
    
      const handleUpdateProfile = (updatedDetails) => {
        setTeacherDetails(updatedDetails);
      };
    
      const handleAddAnnouncement = (announcement) => {
        setAnnouncements([...announcements, announcement]);
      };
    
      const handleDeleteAnnouncement = (id) => {
        setAnnouncements(announcements.filter(announcement => announcement.id !== id));
      };
    
      const [selectedComponent, setSelectedComponent] = useState('Dashboard');
      const [isRightSidebarVisible, setRightSidebarVisible] = useState(true); // State for right sidebar visibility
    
      const handleSelect = (component) => {
        setSelectedComponent(component);
      };
    
      const toggleRightSidebar = () => {
        setRightSidebarVisible(!isRightSidebarVisible);
      };
    
      const sampleClassItem = {
        className: 'Grade 1A',
        dues: [
          { studentName: 'Raj', paidAmount: '₹35', paymentDate: '2024-07-15' },
          { studentName: 'Suraj', paidAmount: '₹65', paymentDate: '2024-07-16' },
        ],
      };
    
      const sampleAssignmentData = {
        className: 'Grade 1A',
        submittedAssignments: 10,
        pendingAssignments: 50,
      };
    
      const sampleAttendanceData = {
        daily: {
          'Grade 1A': {
            labels: ['2024-07-01', '2024-07-02', '2024-07-03'],
            datasets: [
              {
                label: 'Attendance',
                data: [80, 85, 90],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
              {
                label: 'Absence',
                data: [20, 15, 10],
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
              },
            ],
          },
        },
        weekly: {
          'Grade 1A': {
            labels: ['Week 1', 'Week 2', 'Week 3'],
            datasets: [
              {
                label: 'Attendance',
                data: [80, 75, 85],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
              {
                label: 'Absence',
                data: [20, 25, 15],
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
              },
            ],
          },
        },
        monthly: {
          'Grade 1A': {
            labels: ['July', 'August', 'September'],
            datasets: [
              {
                label: 'Attendance',
                data: [90, 80, 85],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
              {
                label: 'Absence',
                data: [10, 20, 15],
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
              },
            ],
          },
        },
      };

  return (
    <div className="dashboard">
      <div className="card-content">
        <Subjects />
      </div>
      <div className="card-content">
        <Classes />
      </div>
      {/* <div className="card-content">
        <DueCard classItem={sampleClassItem} />
      </div> */}
      
        <div className="card-content">
          <TymTblCard /> {/* Add TymTblCard to the dashboard */}
        </div>
        {/* Add more components as needed */}
      
      <div className="card-content">
        <AssignmentCard
          title={sampleAssignmentData.className}
          submittedAssignments={sampleAssignmentData.submittedAssignments}
          pendingAssignments={sampleAssignmentData.pendingAssignments}
        />
      </div>
      <div className="card-content">
        <AttendanceChart data={sampleAttendanceData} selectedClass="Grade 1A" />
      </div>
    </div>
  );
}

export default DashContent;
