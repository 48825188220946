import React, { useState, useEffect } from 'react';
import './ProfileDetails.css';

const ProfileDetails = ({ teacherDetails, onClose }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [daysLeft, setDaysLeft] = useState(0);

  const {
    name = 'N/A',
    phoneNumber = 'N/A',
    email = 'N/A',
    subjects = [],
    subscription = { creationDate: new Date() },
  } = teacherDetails || {};

 
  const today = new Date();
  const validThru = new Date(today); // Subscription start date
  validThru.setDate(today.getDate() - 30); // 30 days ago
  const expiryThru = new Date(today); // Subscription end date
  expiryThru.setDate(today.getDate() + 10); // 10 days in the future

  useEffect(() => {
    const calculateDaysLeft = () => {
      
      const diffTime = expiryThru - validThru;
      const totalDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

     
      const todayToExpiryDiff = expiryThru - today;
      const daysRemaining = Math.ceil(todayToExpiryDiff / (1000 * 60 * 60 * 24));

    
      setDaysLeft(daysRemaining);

     
      const intervalId = setInterval(() => {
        setDaysLeft(prevDays => Math.max(prevDays - 1, 0));
      }, 1000 * 60 * 60 * 24); 

      
      return () => clearInterval(intervalId);
    };

    calculateDaysLeft();
  }, [validThru, expiryThru, today]);

  const isValid = today <= expiryThru;
  const formatDate = (date) => (date ? date.toLocaleDateString() : 'N/A');

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="profile-details-container">
      <div className="profile-details-box">
        <span className="profile-details-close" onClick={onClose}>
          &times;
        </span>
        <h2 className="profile-details-heading">Profile Details</h2>
        <div className="profile-details-content">
          <div className="profile-details-info">
            <p><strong>Name:</strong> {name}</p>
            <p><strong>Phone Number:</strong> {phoneNumber}</p>
            <p><strong>Email:</strong> {email}</p>
            <p><strong>Subjects:</strong> {subjects.join(', ')}</p>
            <p><strong>Subscription Status:</strong> {isValid ? 'Valid' : 'Expired'}</p>
            <p><strong>Valid Thru:</strong> {formatDate(validThru)}</p>
            <p><strong>Expiry Thru:</strong> {formatDate(expiryThru)}</p>
            {isValid && (
              <p><strong>Days Left:</strong> {daysLeft} days</p>
            )}
          </div>
          <div className="profile-details-picture">
            <div className="profile-details-image-upload">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="Profile"
                  className="profile-details-image"
                />
              ) : (
                <div className="profile-details-placeholder">Upload Image</div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="profile-details-file-input"
              />
            </div>
          </div>
        </div>
        {isValid ? (
          <button
            className="profile-details-button"
            onClick={() => console.log('Request Renewal')}
          >
            Request Renewal
          </button>
        ) : (
          <p>Your subscription has expired. Please contact support.</p>
        )}
      </div>
    </div>
  );
};

export default ProfileDetails;

