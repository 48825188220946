import React from "react";
import { useNavigate } from "react-router-dom";
import dueImage from "./due.png"; // Your icon

const TymTblCard = () => {
  const navigate = useNavigate();

  const styles = {
    card: {
      background: "linear-gradient(135deg, #f8d7da 0%, #fff3cd 100%)",
      padding: "15px",
      border: "1px solid #ccc",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      textAlign: "center",
      width: "200px",
      height: "150px",
      margin: "10px auto",
      color: "#333",
      cursor: "pointer",
      transition: "transform 0.2s ease-in-out",
    },
    img: {
      width: "30px",
      height: "auto",
      marginBottom: "10px",
    },
    title: {
      fontSize: "16px",
      color: "#000",
    },
  };

  return (
    <div
      style={styles.card}
      onClick={() => navigate("/timetable")}
    >
      <img src={dueImage} alt="Timetable Icon" style={styles.img} />
      <h3 style={styles.title}>Time Table</h3>
    </div>
  );
};

export default TymTblCard;
